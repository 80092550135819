var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "workerTable",
          attrs: {
            title: "작업자명",
            columns: _vm.grid.columns,
            gridHeight: _vm.grid.height,
            data: _vm.workPermit.workers,
            merge: _vm.grid.merge,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            editable: _vm.editable && _vm.isWriting,
            selection: "multiple",
            rowKey: "sopWorkerId",
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && _vm.isWriting
                    ? _c("c-btn", {
                        attrs: { label: "추가(작업업체 근무자)", icon: "add" },
                        on: { btnClicked: _vm.addVendorUser },
                      })
                    : _vm._e(),
                  _vm.editable && _vm.isWriting
                    ? _c("c-btn", {
                        attrs: { label: "추가(현업)", icon: "add" },
                        on: { btnClicked: _vm.addUser },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  _vm.isWriting &&
                  _vm.workPermit.workers.length > 0
                    ? _c("c-btn", {
                        attrs: {
                          label: "제외",
                          icon: "remove",
                          showLoading: false,
                        },
                        on: { btnClicked: _vm.remove },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }